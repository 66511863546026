/* label{
    font-family: Poppins;
    color:rgb(51, 77, 110)
} */

.container{
  margin-bottom: 15px;
}

.inp{
  width:50%;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #ccc;
  outline:none;
  padding: 10px;
  margin-right: 5px;
}

.inp:focus{
  /* border:1px solid #109cf1; */
  /* box-shadow: 0px 0px 3px 1px #c8def0; */
  border-color: #109cf1;
  box-shadow: 0 0 0 0.2rem #c8def0;
}

.inp-phone{
  width:250px;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #ccc;
  outline:none;
  padding: 10px;
  margin-right: 5px;
}

.inp-phone:focus{
  /* border:1px solid #109cf1; */
  /* box-shadow: 0px 0px 3px 1px #c8def0; */
  border-color: #109cf1;
  box-shadow: 0 0 0 0.2rem #c8def0;
}

.btn{
    padding: 5px;
    border-radius: 5px;
    border: none;
    font-size: 18px;
    font-family: Poppins;
    cursor: pointer;
    color: rgb(51, 77, 110);
    border: 1px solid rgb(51, 77, 110);
    margin-top: 15px;
}

.btn:focus{
  border-color: #109cf1;
  box-shadow: 0 0 0 0.2rem #c8def0;
}

.name-field{
    display:flex;
}

.check{
    height:30px;
    width: 30px;
    border-radius: 5px;
    margin-right: 30px;
    cursor: pointer;
}

.check:focus{
  border-color: #109cf1;
  box-shadow: 0 0 0 0.2rem #c8def0;
}

.check-label{
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  transform-origin: top left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 133%;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    font-size: 20px;
    color: #414D60;
}

.custom-skill{
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.radio{
  margin-top: 15px;
}

.phone-number{
  display: flex;
}

.phone{
  margin-right: 40px;
  margin-bottom: 15px;
}

.error{
  color: #EB5353;
}