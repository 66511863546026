.people-form-table{
   width: 100%;
   table-layout: fixed;
}
.team-people-form-table{
   width: 100%;
   table-layout: fixed;
}
.peopleSettingContainer{
   width: 100%;
}
.point{
   width: 4px;
  height: 4px;
  background-color: #109cf1;
  margin: 3px 0;
  border-radius:20px;
}
.people-form-table tr th, .team-people-form-table tr th {
   font-size: 0.9rem !important;
   background-color: #109cf1;
   color: white !important;
   font-weight: 500 !important;
}
.people-form-table tr td, .team-people-form-table tr td {  
   font-size: 0.8rem !important;
   cursor: normal !important;
   height: 45px !important;
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
}
.team-people-form-table tr{
   margin-bottom: 20px;
}
.people-form-table :last-child{
   text-align: center;
}
.team-people-form-table :last-child{
   text-align: left;
}
.people-form-table :nth-last-child(2){
   text-align: center;
}
.team-people-form-table :nth-last-child(2){
   text-align: left;
}
.people-form-table tr:nth-child(odd) {
   background-color: #ebf6ff;
 }
.people-form-table,.team-people-form-table {
   border: none;
   box-shadow: 0px 2px 7px 2px rgb(218, 238, 255);
}
.addUser{
   text-align: right;
   font-size: 0.7rem;
   color: #109cf1;
   padding-top: 5px;
   font-weight: 600;
   text-transform: uppercase;
   border: 2px solid #80ceff;
   padding: 7px;
   cursor: pointer;
   float: right;
   margin-bottom: 10px;
   border-radius: 2px;
}
.search-people{
   float: right;
   margin-right: 20px;
   border-bottom:  2px solid #a3dcff;
}
.search-people:hover,.search-people:active {
   border-bottom:  2px solid #109cf1;
 }
.search-people input{
   padding: 5px;
   color: #007fce;
   
}
.search-people input::placeholder{
   font-size: 0.7rem;
}
.search-people button{
   font-size: 0.7rem;
   color: white;
   background-color: #109cf1;
   padding: 9px;
   border-radius: 2px;
   position: relative;
   top: 1px;
}


/* ADD USER CSS */
.basic-information{
   background-color: #109cf1;
   color: white;
   padding: 10px;
   margin-bottom: 10px;
   width: 90%;
}
.threeDot{
   width: 60px;
}
.editUser{
   position: absolute;
   top: 10%;
   background:rgb(215, 243, 255);
   padding:20px;
   box-shadow: 2px 2px 10px 2px rgba(100, 100, 100, 0.545);
   min-height: 500px;
   width: 50%;
   left: 25%;
   border-radius: 10px;
   margin-top: 20px;
}
.editUser:hover{
   box-shadow: 0px 0px 15px 4px rgba(79, 79, 79, 0.545);
}
table{
   position: relative;
}
.editUser input{
   display: block;
}
.field{
   width: 43%;
   margin-right: 15px;
}
.addUserForm{
   display: flex;
   flex-wrap: wrap;
}
.submitButton{
   width: 90%;
   margin-left: 10px;

}
