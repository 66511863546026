.profile-picture{
    height: 4rem;
    width: 4em;
    background-color: rgb(236, 248, 255);
    border-radius: 100%;
    display: block;
    margin: auto;
}
.profile-container{
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    font-size: 0.9em;
    margin-bottom: 2rem;
}
.sidbar-list li span{
    font-size: 15px;
    font-weight: 500;
}