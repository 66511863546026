.box {
  padding: 10px;
  background-color: rgb(203, 255, 255);
  width: 100px;
  height: 100px;
  display: flex;
}
.box-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 50px;
  overflow: auto;
  border-right: 2px solid;
  overflow: visible;
  margin: auto;
  margin-top: 30px;
}

.structure {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.node-child-container::before,
.node-child-container::after {
  content: "";
  height: 15px;
  width: 100%;
  position: absolute;
  border-top: 1px solid rgb(1, 50, 87);
}

.structure:first-child::before {
  content: "";
  height: 10px;
  width: 50%;
  right: 50%;
  position: absolute;
  border-top: 2px solid #e5ecef;
}

.structure:last-child::after {
  content: "";
  height: 10px;
  width: 50%;
  left: calc(50% + 2px);
  position: absolute;
  border-top: 2px solid #e5ecef;
  box-shadow: none;
}

.node-child-container:first-child::before,
.node-child-container:last-child::after {
  border: none;
}

.node {
  width: 14rem;
  background-color: rgb(255, 255, 255);
  border: 2px solid #b4b4b4;
  border-radius: 10px;
  margin: 1rem;
  position: relative;
}

.structure-container {
  display: flex;
  justify-content: center;
  padding-left: 10%;
  overflow: auto;
}

.node-child-container {
  display: flex;
}

.node-header {
  background-color: #024d6a;
  height: 40px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border-bottom: 2px solid #b4b4b4;
  color: white;
  font-size: small;
  padding: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.node-body {
  padding: 5px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: small;
  font-weight: 600;
  line-height: 15px;
}

.addButton {
  position: absolute;
  bottom: -1rem;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}
.menuItem {
  padding: 0.3rem 2rem;
  cursor: pointer;
  font-size: 10px;
  color: #024d6a;
  background-color: rgb(220, 245, 255);
}

.menuItem:hover{
  background-color: #b7ebff;
  color: #024d6a;
}

.node-connector {
  position: absolute;
  height: 18px;
  width: 1px;
  background-color: rgb(1, 50, 87);
  left: 50%;
  right: -50%;
  top: -18px;
}
.node-connector-bottom {
  position: absolute;
  height: 18px;
  width: 1px;
  background-color: rgb(1, 50, 87);
  left: 50%;
  right: -50%;
  bottom: -18px;
}
.primary-text {
  color: #024d6a;
}
