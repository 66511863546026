.property-form{
    display: block;
    width: 50%;
}
.form-container-config{
    display: flex;
}
.arrow-down{
    position: relative;
    bottom: -13px;
    right: 29px;
    opacity: 0.5;
}
.form-container-config label{
    font-size: small;
}
.gap{
    height: 50px;
}