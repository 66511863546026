.css-1qqsdnr-MuiAutocomplete-root
  .MuiOutlinedInput-root
  .MuiAutocomplete-input {
  padding: 0.5px 0px 0.5px 0px !important;
  font-size: 8px !important;
}
.css-6od3lo-MuiChip-label {
  font-size: 12px;
  padding: 8px !important;
}

.css-1q79v3g-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon {
  font-size: 14px !important;
}
.css-yf8vq0-MuiSelect-nativeInput {
  opacity: 1 !important;
  padding-left: 2px !important;
}
/* These CSS are overriding the css of material ui to get the desire style outcome */
