.buttonStyled {
  background-color: #109cf1;
  border: 1px solid #109cf1;
  color: white;
  padding: 2px 8px;
  font-size: 12px;
  margin: 5px;
  flex-wrap: wrap;
}
.pagination-btn {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.disableButton {
  background-color: #949494;
  color: white;
  padding: 2px 8px;
  font-size: 12px;
  margin: 5px;
  flex-wrap: wrap;
}
.activeButton {
  background-color: #ebf6ff;
  color: #109cf1;
  border: 1px solid #109cf1;
  padding: 2px 8px;
  font-size: 12px;
  margin: 5px;
  flex-wrap: wrap;
}
.width-full{
  width: 100%;
}
