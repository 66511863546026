.listing-filter-row input, .listing-filter-row select{
   border: 0px solid #109cf1;
   margin: 7px 10px;
   padding: 8px 10px;
   border-radius: 2px;
   background-color: #e9f4ff;
   font-size: small;
}
.listing-container{
   display: flex;
   justify-content: space-between;
   max-height: 75vh;
   overflow: scroll;
}

.create-template{
   background-color: #109cf1;
   color: white;
   font-size: small;
   padding: 7px 15px;
   margin-top: 7px;
   border-radius: 2px;
   position: absolute;
   right: 2.3vw;

}
.edit-template-header{
   display: flex;
}
.subHeading{
   font-size: 1.2rem;
   font-weight: 600;
   color: #109cf1;
}
.template-media{
   display: flex;
   margin-top: 30px;
   text-align: center;
   font-size: small;
}
/* .template-media img{
   margin: 0px 5px;
   border: 2px solid gray;
   padding: 20px;
   border-radius: 3px;
   filter: grayscale(100);
   cursor: pointer;
}
.template-media img:hover{
   filter: none;
   border: 2px solid #109cf1;
} */
.templateBody{
   margin-top: 20px;
}
.templateBody textarea{
   border: 1px solid gray;
   padding: 10px;
   font-size: normal;
   width: 100%;
   margin-top: 10px;
   border-radius: 5px;
   width: 90%;
   height: 150px;
   padding-top: 15px;
   overflow: scroll;

}
.Listing-Container-parent{
   width: 80%;
}
.listing-table{
   max-height: 75vh;
   overflow: scroll;

}
.call-to-action{
   margin-top: 25px;
}
.btn-call-to-action{
   margin-top: 10px;
   margin-right: 10px;
   color: white;
   background-color: #109cf1;
   padding: 7px 15px;
   border-radius: 3px;
   font-size: small;
}
.callToActionContainer input,button{
   /* width: 30%; */
   font-size: small;
   font-weight: bold;
   padding: 5px 10px;
   border-radius: 5px;   
}
.callToActionContainer div{
   display: flex;
   flex-direction: column;
   font-size: small;
}
.callToActionContainer label{
   margin-bottom: 5px;
   color: #109cf1;
   font-weight: bold;
}
.marketing-container{
   border-radius: 20px;
   
}
.editTemplate-first-form{
   width: 100%;
}
.marketing-container1 {
   background-color: #f3fbff;
   margin-top:5px;
   color: #1e1e1e;
   border-radius: 10px;
   display: flex;
   padding: 20px;
   cursor: pointer;
   transition-duration: 0.1s;
}
.marketing-container1:hover{
   background-color: #cbebff;
   transform: scale(1.01);
}


.marketing-image img{
   width: 30px;
   height: 30px;
   margin-right: 20px;
}
.marketing-container1 h1{
   font-weight: bold;
}
.marketing-container1 p{
   font-size: small;
}
.marketing-container-name{
   background-color: #f3fbff;
   padding: 30px;
   margin: 15px 0px;
}
.nameInput
{
   padding: 5px 10px;
   width: 100%;
   border: 1px solid #109bf198;
   border-radius: 5px;
   margin-top: 10px;
}
.nameInput::placeholder{
   font-size: small;
}
.marketing-container-name p{
   font-size: small;
}
.marketing-container-name h1{
   margin-bottom: 5px;
   font-weight: bold;
}
.search-result{
   background-color: white;
   font-size: small;
   
}
.search-result li{
   margin-bottom: 5px;
   cursor: pointer;
   padding: 5px 10px;
}
.search-result li:hover{
   color: #109bf1;
   background-color: #e9f4ff;
}
.next-from-btn
{
   color: white;
   padding: 7px 15px;
   font-size: small;

}
.select-dropdown{
   border: 1px solid gray;
   margin-top: 10px;
   border-radius: 3px;
   padding: 2px 5px;
   margin-bottom: 15px;
}
.header-add-varible{
   font-size: small;
   color: #109bf1;
   font-weight: bold;
   text-align: right;
   margin-top: 10px;
   width: 90%;
   text-align: right;
}
.add-varible-container{
   width: 90%;
   text-align: right;
   cursor: pointer;
 
}

.error-container-text-area{
   background-color: rgb(255, 219, 219);
   color: rgb(59, 6, 6);
   padding: 15px;
   width: 90%;
   border-radius: 5px;
   position: relative;
   bottom: 6px;
   font-size: small;
}
.reset-body{
   color: rgb(146, 0, 0);
   font-size: 12px;
   position: relative;
   top: 5px;
   font-weight: bold;
   cursor: pointer;
   border-radius: 5px;
}
.footer-container{
   width: 90%;
}
.btn-add{
   font-size: small;
   background-color: #109bf1;
   color:white;
   padding: 5px 7px;
   margin-top: 10px;
}
.typeOfAction{
   width: 100px;
   padding: 5px;
}
.country{
   width: 80px;
}
.cut-button{
   margin-top: 27px;
   cursor: pointer;
}
.createTemplatebtn{
   margin-top: 20px;
   background: #3aadf4;
   padding: 10px 40px;
   color: white;
}
