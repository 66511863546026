@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;500&display=swap');

.display-4 {
    font-family: "Roboto",sans-serif;
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2;
    margin-bottom: 0.5rem
}

.lead {
    font-size: 1.25rem;
    font-weight: 300;
     font-family: "Roboto",sans-serif;
}

/* <Box
            sx={{
              width: 400,
            }}
          >
            <Paper elevation={3}>
              <Box sx={{ padding: 1 }}>
                 <h2 className="display-4">Lakshay kumar</h2>
                <p className="lead">lakshya@tbplabs.tech</p>
                <Divider variant="middle" />
                <Typography gutterBottom variant="h4" component="div">
                  Lakshya
                </Typography>
                <Typography color="text.secondary" variant="body2">
                  lakshya@tbplabs.tech
                </Typography>
                <Divider variant="middle" />
                <Box sx={{ m: 2 }}>
                  <Typography gutterBottom variant="body1">
                    Skills
                  </Typography>
                  <Stack direction="row" spacing={1}>
                    <Chip label="Extra Soft" />
                    <Chip color="primary" label="Soft" />
                    <Chip label="Medium" />
                    <Chip label="Hard" />
                  </Stack>
                </Box>
              </Box>
            </Paper> 
          </Box>*/