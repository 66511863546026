.form-input{
   width: 100%;
   /* border: 1px solid rgb(205, 205, 205); */
   border-radius: 3px;
   margin-bottom: 12px;
   padding: 7px;
   margin: 10px;
   background-color: rgb(233 244 255);
   margin-top: 5px;
   font-size: 14px;
}
.form-submit-btn{
   background-color: #109cf1;
   padding: 10px 30px;
   border-radius: 3px;
   color: white;
   font-size: 14px;
   margin-top: 25px;
}
.account-setting{
   font-size: 1.4rem;
   margin-bottom: 20px;
   font-weight: bold;
}
.acc-setting-id{
   font-size: 14px;
   color: #109cf1;
   font-weight: bold;
   margin-bottom: 30px;
}
.acc-title{
   color: #109cf1;
   font-weight: 600;
   font-size: 0.9rem;
}
.acc-details{
   font-weight: 600;
   margin-bottom: 20px;
   width: 100%;
   letter-spacing: 1.5px;
}
.accountSettingcontainer{
   padding: 2vw;
   background-color: #e9f4ff;
   width: 100%;
   display: block;
   border-radius: 5px;
   box-shadow:  2px 2px 10px 2px #49b9ff57;
   padding-bottom: 10px;
}
.acc-card{
   width: 90%;
}
.label{
   font-size: 0.8rem;
   font-weight: bold;
   color: #109cf1;
   margin-left: 10px;
}