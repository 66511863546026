

/* chat area */
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-track {
  background-color: rgb(227, 254, 255);
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgb(11, 146, 219);
}
.chat-area {
  flex-grow: 1;
}

.chat-area {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.chat-area-header {
  display: flex;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background: var(--chat-header-bg);
  display: none;
}

.chat-area-title {
  font-size: 18px;
  font-weight: 600;
}

.chat-area-main {
  flex-grow: 1;
  overflow-y: auto;
}

.message-header-chatArea{
  position: sticky;
  top: 0;
  padding: 1rem 2rem;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  border-bottom: 1px solid rgb(237, 237, 237);
  box-shadow: 2px 2px 3px 2px rgb(238, 238, 238);
  font-weight: 600;
  z-index: 99;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
.three-dot-icon{
  cursor: pointer;
  color: gray;
}

                                /* chat message */
.tooltip {
  position: relative;
  display: inline-block;
}
.tooltip .tooltiptext {
  visibility: hidden;
  opacity: 0;
  transition: opacity .6s;
}

.tooltip:hover .tooltiptext {
  opacity: 100;
  visibility: visible;
}                                

.chat-msg {
  display: flex;
  padding: 0 20px 45px;
}

.time-stamp{
   position: relative;
   font-size: 12px;
   font-weight: 600;
   bottom: 5px;
   color: black;

}

.chat-area-profile {
  width: 32px;
  border-radius: 50%;
  object-fit: cover;
}

.chat-msg-date {
  position: absolute;
  left: calc(100% + 12px);
  bottom: 0;
  font-size: 12px;
  font-weight: 600;
  color: var(--msg-date);
  white-space: nowrap;
}

.chat-msg-name {
  position: absolute;
  left: calc(100% + 12px);
  bottom: 0;
  font-size: 12px;
  font-weight: 600;
  color: var(--msg-date);
  white-space: nowrap;
}

.chat-msg-content {
  margin-left: 12px;
  max-width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}


.chat-msg-img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.chat-msg-profile {
  flex-shrink: 0;
  margin-top: auto;
  margin-bottom: -20px;
  position: relative;
}


.chat-msg-text {
  background-color: var(--chat-text-bg);
  padding: 10px;
  border-radius: 15px 15px 15px 0;
  line-height: 1.5;
  font-size: 14px;
  font-weight: 500;
}
.chat-msg-text + .chat-msg-text {
  margin-top: 10px;
}

.chat-msg-text {
  color: var(--chat-text-color);
}

.chat-msg-text img {
  max-width: 300px;
  width: 100%;
}
                                 /* chat by owner */
.owner {
  flex-direction: row-reverse;
}
.owner .chat-msg-content {
  margin-left: 0;
  margin-right: 12px;
  align-items: flex-end;
}
.owner .chat-msg-text {
  background-color: #5c60f4;
  color: #fff;
  border-radius: 15px 15px 0 15px;
}
.owner .chat-msg-date {
  left: auto;
  right: calc(100% + 12px);
}

/* footer */

.chat-area-footer {
  display: flex;
  width: 100%;
  align-items: center;
  background-color: var(--theme-bg-color);
  position: relative;
}

.chat-area-footer svg {
  color: var(--settings-icon-color);
  width: 20px;
  flex-shrink: 0;
  cursor: pointer;
}
.chat-area-footer-parent{
  display: flex;
    border-top: 1px solid var(--border-color);
    width: 100%;
    padding: 10px 20px;
    align-items: center;
    background-color: var(--theme-bg-color);
    position: relative;
}
.chat-area-footer-parent svg {
  color: var(--settings-icon-color);
  width: 20px;
  flex-shrink: 0;
  cursor: pointer;
}
.chat-area-footer svg:hover {
  color: var(--settings-icon-hover);
}
.chat-area-footer svg + svg {
  margin-left: 12px;
}

.chat-area-footer form {
  border: none;
  color: var(--body-color);
  background-color: var(--input-bg);
  border-radius: 6px;
  font-size: 15px;
  margin: 0 12px;
  width: 100%;
}

.chat-area-footer form textarea {
  border: none;
  color: var(--body-color);
  background-color: var(--input-bg);
  border-radius: 6px;
  font-size: 15px;
  margin: 0 12px;
  width: 100%;
  resize: none;
  padding: 5px;
  height: 35px;
}

.chat-area-footer input::placeholder {
  color: var(--input-chat-color);
}

.pdf{
  display: flex;
  align-items: center;
  gap: 15px;
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px;
}

.pdf img{
  height: 30px;
  width: 30px;
}

.event{
  display: flex;
  justify-content: center;
}