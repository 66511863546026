.main-dropdwon-container {
   z-index: 1;
   margin-left: 10px;
   background-color: #fff;
   border-radius: 7px;
}

.dropdown {
   border: 1px solid #109cf1;
   width: 150px;
   border-radius: 7px;
   padding: 3px 5px;
   font-weight: 500;
   font-size: 0.8rem;
   position: relative;
   max-height: 80px;
}
.dropdown:hover {
   box-shadow: 1px 2px 7px 1px rgba(222, 222, 222, 0.61);
}
.dropdown img {
   display: inline;
   scale: 0.7;
   position: relative;
   bottom: 2px;
}
#downarrowIcon {
   height: 17px;
   position: relative;
   top: 0.5px;
   cursor: pointer;
   text-align: right;
   float: right;
}
.dropdown-subHeading {
   font-size: 12px;
   font-weight: bolder;
   color: #5c5c5c;
   padding-left: 5px;
   padding-top: 5px;
}
.drop-containerIsOpen {
   border: 1px solid #c9c9c9;
   border-radius: 5px;
   background-color: #fff;
   width: 150px;
   display: block;
   margin-top: 5px;
   position: absolute;
   overflow-y: scroll;
   max-height: 70vh;
}
.drop-containerIsClose {
   border: 1px solid #c9c9c9;
   border-radius: 5px;
   background-color: #fff;
   width: 150px;
   display: none;
}
.drop-list {
   padding: 5px 12px;
   font-size: 13px;
   cursor: pointer;
   color: #000;
   display: flex;
   justify-content: space-between;
}
.drop-list:hover {
   background-color: #f1f1f1;
}
#divider {
   width: 60%;
}
.search{
   background-color: #109bf138;
   width: 95%;
   display: block;
   margin: auto;
   padding: 4px 8px;
   font-size: small;
   border-radius: 5px;
}
.search::placeholder{
   font-size: small;
}