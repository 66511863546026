@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

table {
  /* border-collapse: collapse;
  display: block;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin: 0 auto;*/
  overflow-x: auto; 
  /* width: 100%; */
  border-collapse: collapse;
  white-space: nowrap;
}

td,th {
  border: 1px solid #ccc;
  /* border: 1px solid #73777B; */
  padding: 16px;
  color: rgb(51, 77, 110); 
  letter-spacing: 0.01071em;
}

th {
  /* background-color: #f0f0f0; */
  font-family: 'Poppins', sans-serif !important;
  /* font-family: "Roboto","Helvetica","Arial",sans-serif; */
  font-weight: 500;
  border-right: none;
  border-left: none;
  text-align: left;
   color: rgb(4, 12, 22);
  /* color: #7F8487; */
  /* color: rgba(0, 0, 0, 0.87); */
  line-height: 1.5rem;
  font-size: 0.875rem;
  font-size: 0.9rem !important;
}

th:first-child{
  border-left: 1px solid #ccc;
}

thead div:first-child{
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
}

th:last-child{
    border-right: 1px solid #ccc;
}

td:last-child{
    border-right: 1px solid #ccc;
}
td:first-child{
    border-left: 1px solid #ccc;
    /* border-right: 1px solid #ccc; */
}

td{
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;
  border-right: none;
  border-left: none;
  height: 50px;
  color: #413F42;


  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.23;
    letter-spacing: 0.01071em;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    color: rgba(0, 0, 0, 0.87)!important;
    text-align: left;
    padding: 10px;
    font-size: 0.9rem !important;
    cursor: pointer;
}
tr:hover{
  background-color:rgb(248, 248, 248);
}

td:first-child{
  width: 190px;
  white-space: nowrap;
}

.rows:hover{
  background-color: rgb(245,245,245);
}

.r{
    margin-right: 5px;
    margin-top: 5px;
}

.candidate-table{
    display:flex;
    flex-direction: column;
}

.table-screen{
    margin-right: 5px;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    margin-right: 50px;
}


.chips{
   /* margin-bottom: 5px; */
   margin-right: 5px;
}

.chip-container{
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
}

.disabled{
  color: rgba(0, 0, 0, 0.26);
  pointer-events: none;
}


