.sideBarcontainer{
   width: 300px;
   min-width: 300px;
   height: auto;
   min-height: 100vh;
   background-color: #e9f4ff;
   font-family: 'poppins';
   border-radius: 10px;
   padding: 20px 15px;
   margin-right: 25px;
}
.sideBarcontainer li{
   font-size: 13px;
   margin: 10px;
   cursor: pointer;
   color: #109cf1;
   font-weight: 500;
}
.sideBarcontainer h2{
   font-weight: 500;
   margin-bottom: 10px;
}
.sideBarcontainer ul{
   margin-bottom: 20px;
}
.sideBarcontainer li:hover{
   font-weight: bold;
}
.sideBarcontainer li:active{
   font-weight: bold;
}

@media screen and (max-width: 1320px) {
   .sideBarcontainer {
      width: 242px;
      min-width: 230px;
      margin-right: 17px;
   }
 }