.sideBarcontainer{
   width: 300px;
   min-width: 300px;
   height: auto;
   min-height: 100vh;
   background-color: #e9f4ff;
   font-family: 'poppins';
   border-radius: 10px;
   padding: 20px 15px;
   margin-right: 25px;
}
.sideBarcontainer li{
   font-size: 13px;
   margin: 10px;
   cursor: pointer;
   color: #109cf1;
   font-weight: 500;
}
.sideBarcontainer h2{
   font-weight: 500;
   margin-bottom: 10px;
}
.errorClass{
   
}
.inputDiv{
   position: relative;
}
.reqSign{
   position: absolute;
   width: 5px;
   height: 100%;
   left: -8px;
   background-color: #ff0000;
   /* top: 15%; */
   border-radius: 3px 0px 0px 3px;
}
.input-wrapper {
   position: relative;
 }
 .checkbox{
   padding: 10px;
 }
 .checkbox input{
   /* margin: 10px; */
 }
 .checkbox-wrapper{
   display: flex;
   flex-wrap: wrap;
   justify-content: space-evenly;
 }
 .input-wrapper input, .input-wrapper select {
   border: 1px solid gray;
   border-radius: 6px;
   position: relative;
   width: 200px;
   margin: 10px;
   line-height: 6ex;
 }
 .accordian{
   width: 100%;
   margin-bottom: 25px;
   /* -webkit-box-shadow: 0px 0px 21px -8px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 21px -8px rgba(0,0,0,0.75);
box-shadow: 0px 0px 21px -8px rgba(0,0,0,0.75);
border-radius: 10px; */
 }
 
 .input-wrapper label {
   position: absolute;
   top: 0.2ex;
   z-index: 1;
   left: 2em;
   background-color: white;
   padding: 0 5px;
 }
.sideBarcontainer ul{
   margin-bottom: 20px;
}
.sideBarcontainer li:hover{
   font-weight: bold;
}
.sideBarcontainer li:active{
   font-weight: bold;
}

@media screen and (max-width: 1320px) {
   .sideBarcontainer {
      width: 242px;
      min-width: 230px;
      margin-right: 17px;
   }
 }