.marketingi-icons-sidebar {
  width: 3rem;
  box-shadow: -2px 5px 5px rgb(214, 214, 214);
  padding-top: 2vh;
}

.marketingi-icons-sidebar div {
  width: 2.2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  margin: auto;
  margin-bottom: 1rem;
  cursor: pointer;
  transition-duration: 0.2s;
}
.marketingi-icons-sidebar div :hover {
    scale: 1.05;
    transition-duration: 0.5s;
}
.flex-container{
  display: flex;

}
.marketing-sidebar-form{
  height: 90vh;
  width: 300px;
  transition-duration: 0.5s;
  overflow: scroll;
}

.imageStyle {
  filter: grayscale(1);
  
}

.activeImageStyle {
  filter: none;
  color: #00a816 !important;
}