.delete-user-btn{
   color: red;
   margin-top: 15px;
   margin-right: 10px;
   cursor: pointer;
}
.user-team-container{
   display: flex;
   justify-content: space-between;
}
.team-user-desc{
   padding: 5px 15px;
   padding-top: 20px;
   border-radius: 3px;
}

.user-team-container:nth-child(even) {
   background-color: #eaf7ff;
}
.user-details-btn-container{
   display: flex;
   justify-content: space-between;
}
.chooseTeamDropdown{
   position: fixed;
   top: 50%;
   background: linear-gradient(#ffffff,#ffffff);
   border: 1px solid #109cf1;
   padding: 20px 40px;
   border-radius: 5px;
   right: 33%;
   box-shadow: 5px 5px 15px 2px #109bf160;
   top: 50%;
   z-index: 1;
   
   text-align: center;
}
.submit-btn{
   color: white;
   background-color: #109cf1;
   border-radius: 3px;
   padding: 5px 10px;
   font-size: 12px;
   display: block;
    margin: auto;
    margin-top: 20px;
}
.chooseTeamDropdown{
   font-size: 13px;
   color: #109cf1;
   width: 25vw;
   max-width: 400px;
   min-width: 320px;
}
.userAddList{
   padding-bottom: 3px;
   padding: 2px 10px ;
}
.userAddList:hover{
   background-color: #109cf1;
   color:white;
   cursor: pointer;
   transition-duration: 0.3s;
}
.dropdown{
   display: flex;
   cursor: pointer;
   justify-content: space-between;
}
.dropdown span{
   margin-top: -1px;
}
.close-btn{
   font-weight: bold;
    text-align: right;
    display: block;
    right: -20px;
    top: -10px;
    font-size: 18px;
    cursor: pointer;
    position: relative;
}
.add-txt{
   text-align: center;
   font-size: 15px;
}
.dropdown-container{
   margin: auto;
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.team-admin{
   margin-top: 20px;
   display: flex;
   justify-content: space-between;
}
.warningMessage{
   text-align: center;
   margin-top: 20px;
   font-weight: 500;
   display: flex;
   justify-content: center;
   margin:auto;
   margin-top: 20px;
}
.dropdownSearch{
   /* border: 1px solid #109cf1; */
   padding: 3px;
   margin: 2px;
   width: 98%;
   border-radius: 5px;
   background-color: #109bf129;
}