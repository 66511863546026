.msg {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  transition: 0.2s;
  position: relative;
}

/* .msg:hover {
  background-color: var(--msg-hover-bg);
} */

.msg.active {
  background: var(--active-conversation-bg);
  border-left: 4px solid var(--theme-color);
}

.msg-profile.group {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--border-color);
}
.msg-profile.group svg {
  width: 60%;
}

.msg-username {
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 15px;
}
.msg-username:hover > .group-modal{
  display:block;
}

.msg-detail {
  overflow: hidden;
  /* background-color: blue; */
  width: 100%;
}

.msg-content {
  font-weight: 500;
  font-size: 13px;
  display: flex;
  align-items: center;
  /* background-color: red; */
  width: 100%;
  justify-content: space-between;
}

.msg-message {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--msg-message);
}


.msg-date {
  font-size: 14px;
  color: var(--msg-date);
  margin-left: 3px;
}
.msg-date:before {
  content: "•";
  margin-right: 2px;
}

.time-stamp{
  position: absolute;
  right: 0;
}
.unreadMessage{
  background-color: rgb(0 134 255);
  color: white;
  height: 19px;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  width: 20px;
  border-radius: 100%;
  position: absolute;
  right: 8%;
  margin-top: 22px;
}
.group-icon{
  height: 20px;
}
.msg-username{
  display: flex;
  justify-content: space-between;
  transition-duration: 0.5s;
}
.group-modal{
  position: absolute;
  background-color: black;
  color: white;
  font-size: 10px;
  padding: 7px 15px;
  max-width: 250px;
  right: 18%;
  top: 20%;
  border-radius: 5px;
  display: none;
  transition-duration: 0.5s;
}
